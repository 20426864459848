// react
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { useCrossValidation, useHasExpression, useResponseActions } from '@/store/response';
// types
import { NumberOrString, QuestionTypeNumericRules, ResponseStatus } from '@/types/response';
import { convertHtmlToText } from '@/utils/response';
//utils
import { generateNumericPlaceholder } from '@/utils/string-helper';
// icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// mui
import { TextField, Tooltip, Typography } from '@mui/material';
// data grid
import { GridCellModes, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
// components
import { AutoComputedField } from './AutoComputedField';
import { ConditionalField } from './ConditionalField';

// Regex to match a valid numeric string:
// - Optional negative sign at the start
// - One or more digits
// - Optional decimal point followed by one or more digits
const NUMERIC_REGEX = /^-?\d+(\.\d+)?$/;
const NumberFieldRenderer = (params: GridRenderCellParams) => {
  const { value, id: rowId, field: questionId, colDef } = params;
  const leadingQuestionId = (colDef as any).leadingQuestionId as NumberOrString;
  const { setResponse } = useResponseActions();

  const { t } = useTranslation('questionnaire', {
    keyPrefix: 'questionnaire.response.question.validation.numeric',
  });

  const rules: QuestionTypeNumericRules = (colDef as any)?.rules;
  const min = rules?.lo ?? 0;
  const max = rules?.hi ?? Infinity;

  const [
    error,
    setError,
  ] = useState<string>('');

  const hasExpression = useHasExpression({
    leadingQuestionId,
    rowId,
    questionId,
  });

  const validation = useCrossValidation({ leadingQuestionId, rowId, questionId });

  useEffect(() => {
    if (validation && value !== '') {
      setResponse({
        leadingQuestionId,
        rowId,
        questionId,
        content: value,
        status: value ? ResponseStatus.Answered : ResponseStatus.Unanswered,
        errorMessages: [
          validation,
        ],
      });
    }
  }, [
    validation,
  ]);

  useEffect(() => {
    let error;
    const numericValue = parseFloat(value);
    if (value === '') setError('');
    else if (min !== undefined && numericValue < min) error = t('minCheck', { min: rules?.lo ?? 0 });
    else if (max !== undefined && numericValue > max) error = t('maxCheck', { max: rules?.hi ?? Infinity });
    else error = '';
    setError(error);
  }, [
    value,
  ]);

  return hasExpression ? (
    <AutoComputedField {...params} />
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: colDef.width || '100%',
        maxWidth: colDef.computedWidth,
        overflowX: 'hidden',
      }}
    >
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
        {error !== '' && (
          <Tooltip
            title={error}
            placement="top"
            arrow
            sx={{ alignSelf: 'self-start' }}
          >
            <ErrorOutlineIcon color="error" />
          </Tooltip>
        )}
        {error === '' && validation && (
          <Tooltip
            title={convertHtmlToText(validation)}
            placement="top"
            arrow
            sx={{ alignSelf: 'self-start' }}
          >
            <ErrorOutlineIcon color="error" />
          </Tooltip>
        )}
      </div>

      <Typography
        sx={{
          display: 'block',
          flexWrap: 'wrap',
          overflow: 'hidden',
          WebkitBoxOrient: 'horizontal',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Typography>
    </div>
  );
};

export const NumberField = (params: GridRenderCellParams) => {
  return (
    <ConditionalField params={params}>
      <NumberFieldRenderer {...params} />
    </ConditionalField>
  );
};

const CustomComponent = (props: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const { id, field, colDef, hasFocus } = props;
  const currentValue = apiRef.current.getCellValue(id, field);
  const [
    internalValue,
    setInternalValue,
  ] = useState(currentValue);

  const rules: QuestionTypeNumericRules = (colDef as any)?.rules;

  const min = rules?.lo ?? 0;
  const max = rules?.hi ?? Infinity;
  const decimals = rules?.decimals ?? 4;
  const [
    inputRef,
    setInputRef,
  ] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (apiRef.current) {
      const currentValue = apiRef.current.getCellValue(id, field);
      setInternalValue(currentValue);
    }
  }, [
    inputRef,
  ]);

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef?.focus();
    }
  }, [
    hasFocus,
    inputRef,
  ]);

  const onBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: internalValue,
      debounceMs: 200,
    });
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    // Check if the newValue is numeric or empty (to allow clear operation)
    if (newValue === '' || (NUMERIC_REGEX.test(newValue) && isValueInRange(newValue))) {
      if (newValue === '') setInternalValue(newValue);
      else setInternalValue(Number(Number(newValue).toFixed(decimals)));
    } else if (newValue === '' || NUMERIC_REGEX.test(newValue)) setInternalValue(newValue);
  };

  const handleKeyDownEvent = async (event: React.KeyboardEvent) => {
    if (
      [
        'Enter',
        'Tab',
      ].includes(event.key)
    ) {
      if (NUMERIC_REGEX.test(internalValue) && isValueInRange(internalValue)) {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: Number(Number(internalValue).toFixed(decimals)),
          debounceMs: 200,
        });
      } else {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: internalValue,
          debounceMs: 200,
        });
      }

      if (apiRef.current.getCellMode(id, field) === GridCellModes.Edit) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
    if (
      [
        'e',
        'E',
      ].includes(event.key)
    ) {
      event.preventDefault();
    }
  };
  const isValueInRange = (newValue: string): boolean => {
    const numericValue = parseFloat(newValue);
    if (min !== undefined && numericValue < min) return false;
    if (max !== undefined && numericValue > max) return false;
    return true;
  };

  return (
    <TextField
      type="number"
      placeholder={generateNumericPlaceholder(decimals)}
      inputRef={ref => setInputRef(ref)}
      value={internalValue}
      onChange={handleChange}
      onBlur={onBlur}
      onClick={e => e.stopPropagation()}
      onKeyDown={handleKeyDownEvent}
      sx={{
        '& fieldset': { border: 'none' },
        width: '100%',
      }}
    />
  );
};

export const NumberFieldEdit = (params: GridRenderCellParams) => <CustomComponent {...params} />;
