// types
import { sortBy, uniqBy } from 'lodash';
import {
  Attachment,
  Block,
  BulkUpdateInput,
  ClauseKind,
  ComplexQuestionProgress,
  ContentBlock,
  ContentBlockWithMetaData,
  ExtendFile,
  LocalResponse,
  Maybe,
  NumberOrString,
  Option,
  OptionResponse,
  Progress,
  ProgressPoints,
  ProgressStatus,
  Question,
  QuestionClause,
  QuestionGroupedOption,
  QuestionResponse,
  QuestionTypeMatrix,
  Questionnaire,
  QuestionnaireProgress,
  ResponseAttachment,
  ResponseStatus,
  ResponsesQuery,
  Row,
  Section,
  SectionType,
} from '@/types/response';
// utils
import { parseValidDate } from './date';

export function getProgressPercent(progress: ProgressPoints) {
  const x = progress?.answered + progress?.reviewed + progress?.skipped;
  return (x / (progress?.total - progress?.conditional)) * 100;
}

export const initProgressPoints = (total = 0) => {
  return {
    answered: 0,
    reviewed: 0,
    skipped: 0,
    started: 0,
    conditional: 0,
    total,
  };
};

/**
 *
 * returns skipped & missing questions before submit
 */

export const getSubmitAlerts = (progress: ProgressPoints) => {
  const { skipped, answered, reviewed, total: totalQuestion, conditional } = progress;
  const total = totalQuestion - conditional;
  return {
    skipped: (skipped / total) * 100 || undefined,
    missing: ((total - (answered + reviewed + skipped)) / total) * 100 || undefined,
  };
};

/**
 * @param attachments
 * transform Attachement to ExtendFile
 */
export const toExtendFile = (attachments: Attachment[] | undefined): ExtendFile[] => {
  if (!attachments) return [];
  return attachments.map(attachement => {
    const { id, fileName, fileSize, fileType, loading } = attachement;
    const file = new File([], fileName, {
      type: fileType,
    });

    return Object.assign(file, {
      attachmentId: id,
      loading,
      fileSize,
    });
  });
};

/**
 * @param file
 * @param loading
 * transform Extendfile to Attachment
 * loading = true, file is being uploaded to azure storage
 */
export const toAttachment = (file: ExtendFile, loading = true): Attachment => {
  // TODO: we should set a proper hash here
  const { attachmentId, type, name, size, hash = '' } = file;
  return {
    id: attachmentId as number,
    fileName: name,
    fileType: type,
    fileSize: size,
    hash,
    loading,
  };
};

export const toResponses = (
  data: ResponsesQuery['responses'],
  questionnaireProgress: QuestionnaireProgress,
  clauses: Map<NumberOrString, QuestionClause>,
  idSet: Set<NumberOrString>,
) => {
  const leadingIds = new Set<string>();
  const responses: Record<string, QuestionResponse> = {};
  let progress = { ...questionnaireProgress };

  // filter out irrelevant responses
  // ... responses associated with questions no longer present in the questionnaire (e.g., due to tag changes, etc.)
  const apiResponses = data.responses.filter(response => {
    const { questionId, rowId } = response;

    // the question & the row (if applicable) must be present in the questionnaire
    // (i.e., they must be present in the idSet)
    return idSet.has(questionId) && (!rowId || idSet.has(rowId));
  });

  apiResponses.forEach(response => {
    const { questionId, status, content, questionType, attachments, leadingQuestionId, rowId } = response;

    const key = keyFromIds(leadingQuestionId, rowId, questionId);

    //  save leading questions ids for updating their progress at a later time
    if (leadingQuestionId) leadingIds.add(leadingQuestionId);
    const actualStatus = status == ResponseStatus.Unanswered && attachments?.length ? ResponseStatus.Answered : status;

    responses[key] = {
      questionId,
      content: questionType === 'DATE' ? parseValidDate(content) : content,
      attachments: attachments as ResponseAttachment[],
      status: actualStatus as ResponseStatus,
      leadingQuestionId: leadingQuestionId as NumberOrString,
      rowId: rowId as NumberOrString,
    };
  });

  // set response for conditional questions
  const columnClauses: QuestionClause[] = [];
  clauses?.forEach((clause, key) => {
    if (clause.kind === ClauseKind.Column) columnClauses.push(clause);
    else if (
      [
        ClauseKind.Question,
        ClauseKind.ChildQuestion,
      ].includes(clause.kind)
    ) {
      const { leadingQuestionId, rowId, questionId } = clause;

      // if the question / row is not present in the questionnaire, skip it
      if (!idSet.has(questionId) || (rowId && !idSet.has(rowId))) return;

      responses[key] = {
        leadingQuestionId,
        rowId,
        questionId,
        content: responses?.[key]?.content ?? null,
        status: responses?.[key]?.status || ResponseStatus.Conditional,
        attachments: responses?.[key]?.attachments ?? [],
      };
    }
  });

  // make sure column clauses are processed after question's clauses
  columnClauses.forEach(clause => {
    const { leadingQuestionId, questionId } = clause;
    if (progress.complexQuestions[leadingQuestionId as NumberOrString]) {
      const rows = Object.keys(progress.complexQuestions[leadingQuestionId as NumberOrString].rows);

      rows.forEach(rowId => {
        // skip if the question/row is not present in the questionnaire
        if (!idSet.has(questionId) || !idSet.has(rowId)) return;
        const key = keyFromIds(leadingQuestionId, rowId, questionId);
        responses[key] = {
          leadingQuestionId,
          rowId,
          questionId,
          content: responses?.[key]?.content || null,
          status: responses?.[key]?.status || ResponseStatus.Conditional,
        };
      });
    }
  });

  // update progress of all questions except complex ones
  for (const key in responses) {
    if (!leadingIds.has(key)) progress = updateProgress(progress, responses[key]);
  }

  // update complex questions (responses & progress)
  leadingIds.forEach(id => {
    responses[id] = {
      questionId: id,
      content: null,
      status: [
        ResponseStatus.Skipped,
        ResponseStatus.Conditional,
        ResponseStatus.Reviewed,
      ].includes(responses[id]?.status)
        ? responses[id]?.status
        : getComplexQuestionStatus(id, progress.complexQuestions, clauses).status,
    };
    progress = updateProgress(progress, responses[id]);
  });

  return { responses, progress };
};

export function initializeStore(questionnaire: Questionnaire, qp: QuestionnaireProgress) {
  const progress = { ...qp };
  const idSet = new Set<NumberOrString>();

  function initializeSection(section: Section) {
    const sectionProgress = progress.sections[section.id] || {
      totalQuestions: 0,
      answeredQuestions: new Set(),
      reviewedQuestions: new Set(),
      startedQuestions: new Set(),
      skippedQuestions: new Set(),
      conditionalQuestions: new Set(),
    };

    progress.sections[section.id] = {
      ...sectionProgress,
      totalQuestions: section.questions.length,
    };
  }

  function initializeComplexQuestionProgress(section: Section, question: Question) {
    progress.sectionMap[question.id] = section.id;
    idSet.add(question.id);
    // is complex ? then init complex question progress
    if (question.type.__typename === 'QuestionTypeMatrix') {
      const rows = progress.complexQuestions[question.id]?.rows || {};
      const columns = question.type.columns;

      progress.complexQuestions[question.id] = {
        rows,
      };
      columns.forEach(column => idSet.add(column.id));
      question.type.rows.forEach(row => {
        idSet.add(row.id);
        const rowsProgress = rows[row.id] ?? {
          totalQuestions: columns.length,
          answeredQuestions: new Set(),
          reviewedQuestions: new Set(),
          skippedQuestions: new Set(),
          startedQuestions: new Set(),
          conditionalQuestions: new Set(),
        };

        progress.complexQuestions[question.id].rows[row.id] = {
          ...rowsProgress,
        };
      });
    }
  }

  function getRowMapping(questionnaire: Questionnaire) {
    const dynamicRowMapping = new Map<NumberOrString, { id: string; name: string }[]>();
    const staticRowMapping = new Map<NumberOrString, { id: string; name: string }[]>();
    questionnaire.sections?.forEach(section => {
      section?.questions?.forEach(question => {
        if (question?.type.__typename === 'QuestionTypeMatrix') {
          const dynamicRows = question.type.rows
            .filter(row => row.isCustom)
            .map(row => ({ id: row.id, name: row.title! }));
          const staticRows = question.type.rows
            .filter(row => !row.isCustom)
            .map(row => ({ id: row.id, name: row.title! }));
          if (dynamicRows.length > 0) dynamicRowMapping.set(question.id, dynamicRows);
          if (staticRows.length > 0) staticRowMapping.set(question.id, staticRows);
        }
      });
    });
    return { dynamicRowMapping, staticRowMapping };
  }

  questionnaire.sections
    ?.filter(section => section?.type !== SectionType.INFORMATION_BLOCK)
    .forEach(section => {
      if (section) {
        initializeSection(section);
        section.questions?.forEach(question => {
          if (question) {
            initializeComplexQuestionProgress(section, question);
          }
        });
      }
    });

  return { progress, idSet, ...getRowMapping(questionnaire) };
}

const updateProgressStatus = (progress: Progress, key: NumberOrString, status: ResponseStatus): Progress => {
  try {
    progress?.answeredQuestions.delete(key);
    progress?.reviewedQuestions.delete(key);
    progress?.startedQuestions.delete(key);
    progress?.skippedQuestions.delete(key);
    progress?.conditionalQuestions.delete(key);

    if (status === ResponseStatus.Answered) {
      progress?.answeredQuestions.add(key);
    } else if (status === ResponseStatus.Reviewed) {
      progress?.reviewedQuestions.add(key);
    } else if (status === ResponseStatus.Progress) {
      progress?.startedQuestions.add(key);
    } else if (status === ResponseStatus.Skipped) {
      progress?.skippedQuestions.add(key);
    } else if (status === ResponseStatus.Conditional) {
      progress?.conditionalQuestions.add(key);
    }
  } catch (e) {
    console.error(e);
  }

  return progress;
};

export const updateProgress = (progress: QuestionnaireProgress, response: LocalResponse): QuestionnaireProgress => {
  const { leadingQuestionId, rowId, questionId, status } = response;
  const key = keyFromResponse(response);

  if (leadingQuestionId && rowId) {
    progress.complexQuestions[leadingQuestionId].rows[rowId] = updateProgressStatus(
      progress.complexQuestions[leadingQuestionId].rows[rowId],
      key,
      status,
    );
  } else {
    const sectionId = progress.sectionMap[leadingQuestionId ?? questionId];
    progress.sections[sectionId] = updateProgressStatus(progress.sections[sectionId], questionId, status);
  }

  return progress;
};

export function toBulkUpdateInput(
  responses: Record<string, QuestionResponse>,
  dirtyfields: Record<string, boolean>,
  errorFields: Record<string, boolean>,
) {
  const bulkUpdateInput: BulkUpdateInput[] = [];

  for (const key in dirtyfields) {
    if (dirtyfields[key] && !errorFields[key]) {
      // if error field for given key exists, then we will not persist this value to backend
      if (responses[key]?.questionId)
        bulkUpdateInput.push({
          rowId: responses[key]?.rowId as string,
          id: responses[key]?.questionId as string,
          updateResponseInput: {
            content: responses[key].content || null,
            status: responses[key].status,
          },
        });
    }
  }

  return bulkUpdateInput;
}

export const keyFromIds = (
  leadingQuestionId?: NumberOrString | null,
  rowId?: NumberOrString | null,
  questionId?: NumberOrString | null,
  optionId?: NumberOrString | null,
) => {
  return [
    leadingQuestionId,
    rowId,
    questionId,
    optionId,
  ]
    .filter(field => field)
    .join('-');
};

export const keyFromResponse = (response: LocalResponse) => {
  const { leadingQuestionId, rowId, questionId } = response;
  return keyFromIds(leadingQuestionId, rowId, questionId);
};

export const keyFromRowId = (leadingQuestionId: NumberOrString, rowId: NumberOrString) =>
  `${leadingQuestionId}-${rowId}`;

export const getComplexQuestionStatus = (
  leadingQuestionId: NumberOrString,
  complexQuestions: Record<NumberOrString, ComplexQuestionProgress>,
  clauses: Map<NumberOrString, QuestionClause>,
) => {
  let totalRows = 0;
  let completedRows = 0;
  let totalQuestions = 0;
  let completedQuestions = 0;

  const rows = complexQuestions[leadingQuestionId]?.rows;

  for (const key in rows) {
    if (clauses.get(keyFromRowId(leadingQuestionId, key))?.display === false) continue;

    totalRows++;
    const rowTotalQuestions = rows[key]?.totalQuestions - rows[key]?.conditionalQuestions.size;
    const rowCompletedQuestions = rows[key]?.answeredQuestions.size;
    // count completed rows
    if (rowTotalQuestions === rowCompletedQuestions) completedRows++;
    totalQuestions += rowTotalQuestions;
    completedQuestions += rowCompletedQuestions;
  }

  return {
    totalRows,
    completedRows,
    status:
      completedQuestions === 0
        ? ResponseStatus.Unanswered
        : completedQuestions !== totalQuestions
          ? ResponseStatus.Progress
          : ResponseStatus.Answered,
  };
};

export const getRowStatus = (totalQuestions: number, completedQuestions: number) => {
  return completedQuestions === 0
    ? ProgressStatus.NotStarted
    : totalQuestions === completedQuestions
      ? ProgressStatus.Completed
      : ProgressStatus.Progress;
};

export const updateResponse = (
  responses: Record<NumberOrString, LocalResponse>,
  display: boolean,
  key: NumberOrString,
): Record<NumberOrString, LocalResponse> => {
  if (!responses[key])
    return {
      ...responses,
      [key]: {
        content: null,
        status: !display ? ResponseStatus.Conditional : ResponseStatus.Unanswered,
      } as unknown as LocalResponse, // This indicates that there is no response for this question in the database. but, we need to change the status for statistical purposes.
    };

  const response = {
    ...responses[key],
  };

  const newStatus = (() => {
    if (!display) {
      return ResponseStatus.Conditional;
    }
    if (
      [
        ResponseStatus.Reviewed,
        ResponseStatus.Skipped,
      ].includes(response?.status)
    ) {
      return response?.status;
    }
    if (!response?.content && !response?.attachments?.length) {
      return ResponseStatus.Unanswered;
    }

    if (response?.content || response?.attachments?.length) {
      return ResponseStatus.Answered;
    }

    return response?.status;
  })();

  response.status = newStatus;

  return {
    ...responses,
    [key]: response,
  };
};

export const updateResponseInPlace = (
  responses: Record<NumberOrString, LocalResponse>,
  display: boolean,
  key: NumberOrString,
) => {
  if (!responses[key]) {
    responses[key] = {
      content: null,
      status: !display ? ResponseStatus.Conditional : ResponseStatus.Unanswered,
    } as unknown as LocalResponse; // This indicates that there is no response for this question in the database. but, we need to change the status for statistical purposes.
    return;
  }

  const response = {
    ...responses[key],
  };

  const newStatus = (() => {
    if (!display) {
      return ResponseStatus.Conditional;
    }
    if (
      [
        ResponseStatus.Reviewed,
        ResponseStatus.Skipped,
      ].includes(response?.status)
    ) {
      return response?.status;
    }
    if (!response?.content && !response?.attachments?.length) {
      return ResponseStatus.Unanswered;
    }

    if (response?.content || response?.attachments?.length) {
      return ResponseStatus.Answered;
    }

    return response?.status;
  })();

  response.status = newStatus;
  responses[key] = response;
};

export const updateComplexResponse = (
  responses: Record<NumberOrString, LocalResponse>,
  clauses: Map<NumberOrString, QuestionClause>,
  progress: QuestionnaireProgress,
  leadingQuestionId: NumberOrString,
  display?: boolean,
) => {
  const unChangesStatus = [
    ResponseStatus.Skipped,
    ResponseStatus.Reviewed,
  ];
  const response = {
    ...responses[leadingQuestionId],
    status:
      /**
       * if the "leading question display" flag is set to false,
       * there's no necessity to deduce its status from child questions;
       * the status automatically defaults to 'conditional.'
       */
      display === false
        ? ResponseStatus.Conditional
        : unChangesStatus.includes(responses[leadingQuestionId]?.status)
          ? responses[leadingQuestionId]?.status
          : getComplexQuestionStatus(leadingQuestionId as NumberOrString, progress.complexQuestions, clauses)?.status,
    questionId: leadingQuestionId,
  };

  responses = {
    ...responses,
    [leadingQuestionId]: response,
  };

  return responses;
};

export const updateComplexResponseInPlace = (
  responses: Record<NumberOrString, LocalResponse>,
  clauses: Map<NumberOrString, QuestionClause>,
  progress: QuestionnaireProgress,
  leadingQuestionId: NumberOrString,
  display?: boolean,
) => {
  const unChangesStatus = [
    ResponseStatus.Skipped,
    ResponseStatus.Reviewed,
  ];
  const response = {
    ...responses[leadingQuestionId],
    status:
      /**
       * if the "leading question display" flag is set to false,
       * there's no necessity to deduce its status from child questions;
       * the status automatically defaults to 'conditional.'
       */
      display === false
        ? ResponseStatus.Conditional
        : unChangesStatus.includes(responses[leadingQuestionId]?.status)
          ? responses[leadingQuestionId]?.status
          : getComplexQuestionStatus(leadingQuestionId as NumberOrString, progress.complexQuestions, clauses)?.status,
    questionId: leadingQuestionId,
  };

  responses[leadingQuestionId] = response;
};

export const cleanAfterRowDeletion = (
  leadingQuestionId: NumberOrString,
  rowIds: NumberOrString[],
  responses: Record<string, QuestionResponse>,
  clauses: Map<NumberOrString, QuestionClause>,
  expressions: Map<NumberOrString, NumberOrString[]>,
  progress: QuestionnaireProgress,
  dirtyFields: Record<string, boolean>,
) => {
  for (const rowId of rowIds) {
    const key = keyFromIds(leadingQuestionId, rowId);

    // remove entries from responses that match or contain the key
    for (const responseKey in responses) {
      if (responseKey.includes(key)) {
        delete responses[responseKey];
      }
    }

    // remove entries from clauses that match or contain the key
    clauses.forEach((_, clauseKey) => {
      if ((clauseKey as string).includes(key)) {
        clauses.delete(clauseKey);
      }
    });

    expressions.forEach((_, expressionKey) => {
      if ((expressionKey as string).includes(key)) {
        expressions.delete(expressionKey);
      }
    });

    delete progress.complexQuestions[leadingQuestionId].rows[rowId];
  }

  const status = getComplexQuestionStatus(leadingQuestionId, progress.complexQuestions, clauses).status;
  responses[leadingQuestionId] = {
    content: null,
    questionId: leadingQuestionId,
    status,
  };
  dirtyFields[leadingQuestionId] = true;
  // update section progress
  const sectionId = progress.sectionMap[leadingQuestionId];
  progress.sections[sectionId] = updateProgressStatus(progress.sections[sectionId], leadingQuestionId, status);

  return { responses, clauses, progress, expressions, dirtyFields };
};

export const updateAfterRowAddition = (
  leadingQuestionId: NumberOrString,
  responses: Record<string, QuestionResponse>,
  progress: QuestionnaireProgress,
  dirtyFields: Record<string, boolean>,
) => {
  // upon adding a row to a complex question, the status is updated to 'Progress'
  const status = ResponseStatus.Progress;
  responses[leadingQuestionId] = {
    content: null,
    questionId: leadingQuestionId,
    status,
  };

  dirtyFields[leadingQuestionId] = true;

  // update section progress
  const sectionId = progress.sectionMap[leadingQuestionId];
  progress.sections[sectionId] = updateProgressStatus(progress.sections[sectionId], leadingQuestionId, status);

  return { responses, progress, dirtyFields };
};

export const updateErrorFields = (
  errorFields: Record<string, boolean>,
  errorMessages: string[] | undefined,
  keys: NumberOrString[],
) => {
  const tempErrorFields = { ...errorFields };
  if (errorMessages && errorMessages.length > 0) {
    keys.forEach(key => {
      tempErrorFields[key] = true;
    });
  } else {
    keys.forEach(key => {
      if (tempErrorFields[key]) delete tempErrorFields[key];
    });
  }
  return { errorFields: tempErrorFields };
};

// aux function that checks if a specific cell is locked
export const isCellLocked = (key: NumberOrString, clauses: Map<NumberOrString, QuestionClause>): boolean => {
  return clauses.get(key)?.display === false;
};

// check all auto cal cells are answered
export const checkAllAnswered = (
  leadingQuestionId: NumberOrString,
  rows: Row[],
  columns: Question[],
  responses: Record<string, QuestionResponse>,
  expressions: Map<NumberOrString, NumberOrString[]>,
  clauses: Map<NumberOrString, QuestionClause>,
): boolean => {
  for (const row of rows) {
    for (const column of columns) {
      const key = keyFromIds(leadingQuestionId, row.id, column.id);

      const isCellNotLocked = !isCellLocked(key, clauses);
      if (isCellNotLocked && expressions.has(key) && responses[key]?.status !== ResponseStatus.Answered) {
        return false;
      }
    }
  }
  return true;
};

export const isArrayofOptions = (arr: any[]): arr is Option[] => {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.every(item => isOption(item));
};

export const isObject = (candidate: unknown): candidate is object => {
  return candidate !== null && typeof candidate === 'object' && !Array.isArray(candidate);
};

export const isOption = (candidate: unknown): candidate is Option => {
  if (!isObject(candidate)) {
    return false;
  }

  const { id, name } = candidate as Partial<Option>;

  return typeof id === 'string' && typeof name === 'string';
};

export const isGroupedOption = (candidate: unknown): candidate is QuestionGroupedOption => {
  if (!isObject(candidate)) {
    return false;
  }

  const { group, options } = candidate as Partial<QuestionGroupedOption>;

  return typeof group === 'string' && Array.isArray(options);
};

export const isArrayofGroupedOptions = (arr: unknown[]): arr is QuestionGroupedOption[] => {
  if (!Array.isArray(arr)) return false;
  return arr?.some(item => isGroupedOption(item));
};

export const processOptionResponse = (response: LocalResponse, optionId: NumberOrString): OptionResponse => {
  if (!response?.content) {
    return {
      type: 'unknown',
      value: undefined,
      status: ResponseStatus.Unanswered,
    };
  }

  const { content, status } = response;

  // determine the type of the content and parse accordingly
  if (isOption(content)) {
    const value = content?.id === optionId ? null : content;
    return {
      type: 'option',
      value,
      status: value ? status : ResponseStatus.Unanswered,
    };
  } else if (isArrayofOptions(content)) {
    const value = content?.filter((option: Option) => option?.id !== optionId);
    return {
      type: 'options',
      value,
      status: value.length === 0 ? ResponseStatus.Unanswered : status,
    };
  } else if (isArrayofGroupedOptions(content)) {
    const value: QuestionGroupedOption[] = [];

    content?.forEach(group => {
      const options = group.options.filter(opt => opt.id !== optionId);
      if (options.length > 0) value.push({ ...group, options });
    });

    return {
      type: 'groupedOptions',
      value,
      status: value.length === 0 ? ResponseStatus.Unanswered : status,
    };
  }

  // default return for unrecognized types
  return {
    type: 'unknown',
    value: undefined,
    status: ResponseStatus.Unanswered,
  };
};

export const getColumnsMap = (currentQuestion: Maybe<Question> | undefined): Map<string, string> | undefined => {
  if (currentQuestion?.type?.__typename === 'QuestionTypeMatrix') {
    // a map to store column IDs and text
    const columnsMap = new Map<string, string>();
    const columns = currentQuestion.type.columns;

    if (columns) {
      columns.forEach((column: Question) => {
        columnsMap.set(column.id, column.text);
      });
    }
    return columnsMap.size > 0 ? columnsMap : undefined;
  }
  return undefined;
};

export const getContentBlocksOfColumns = (
  columnsMap: Map<string, string> | undefined,
  previewGuidance: Map<NumberOrString, ContentBlock[]>,
): Map<string, Block[]> | undefined => {
  const columnsBlocksMap = new Map<string, Block[]>();

  columnsMap?.forEach((columnText, columnId) => {
    columnsBlocksMap.set(columnId, toBlocks(previewGuidance?.get(columnId)));
  });
  return columnsBlocksMap.size > 0 ? columnsBlocksMap : undefined;
};

export const toBlocks = (contentBlocks?: ContentBlock[], columnsBlocks?: Map<string, Block[]> | undefined): Block[] => {
  const blocks: Block[] = [];

  // Extract content block types at question level
  contentBlocks?.forEach((contentBlock, index) => {
    if (contentBlock)
      blocks.push({
        text: contentBlock?.text ?? '',
        order: index + 1,
        name: contentBlock.contentBlockType.name,
      });
  });

  // Extract content block types from columnsBlocks
  columnsBlocks?.forEach((columnBlocks: Block[]) => {
    columnBlocks?.forEach((block: Block) => {
      if (!blocks.some(existingBlock => existingBlock.name === block.name))
        blocks.push({
          text: '',
          order: blocks.length + 1,
          name: block.name,
        });
    });
  });
  return sortBy(blocks, 'order');
};

export function mergeContentBlocks(
  question: Question,
  blocks: ContentBlock[],
  columnsBlocks: Record<string, ContentBlock[] | null>,
) {
  const mergedColumnsBlocks = Object.entries(columnsBlocks ?? {}).flatMap(
    ([
      columnId,
      columnBlocks,
    ]) => {
      const column = (question.type as QuestionTypeMatrix)?.columns.find(c => c.id === columnId);
      if (!column || !columnBlocks) return [];
      return columnBlocks.map(b => ({
        title: column.text,
        id: column.id,
        type: 'ColumnBlock',
        block: b,
      }));
    },
  );
  const transformedBlocks = uniqBy(
    blocks.map(b => ({
      title: question.text,
      id: question.id,
      type: 'QuestionBlock',
      block: b,
    })),
    b => b.block.contentBlockType.name,
  );

  const mergedByType = [
    ...transformedBlocks,
    ...mergedColumnsBlocks,
  ].reduce(
    (acc, block) => {
      const type = block.block.contentBlockType.name;
      if (!acc[type]) acc[type] = [];
      acc[type].push(block);
      return acc;
    },
    {} as Record<string, ContentBlockWithMetaData[]>,
  );

  return Object.values(mergedByType).sort(
    (a, b) => a[0].block.contentBlockType.order - b[0].block.contentBlockType.order,
  );
}

export const scrollToGivenQuestionIndex = (idx: number) => {
  const element = document.getElementById(`question-${idx}`);

  if (element) {
    element.scrollIntoView({ block: 'start', behavior: 'instant' });
  }
};
const tableStyling = `
<style>
  table {
    border-collapse: collapse;
    border: 1px solid #BBCBD2;
    width: 100%;

  }
  th {
    background-color: #475463;
    color: white;
  }
  th, td {
    border: 1px solid #BBCBD2


  }

  blockquote {
    border: '1px solid #ccc',
    borderLeft: '5px solid #ccc',
    borderRadius: '5px',
    margin: '10px',
    padding: '10px',
  }

  blockquote p {
    display: 'inline',
  }
</style>
`;

export const formatTables = (htmlContent: string) => {
  return tableStyling + htmlContent;
};

export const replaceTablesWithLinks = (content: any, questionId?: string) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(content, 'text/html');
  const tables = htmlDoc.querySelectorAll('table');

  tables.forEach((table, index) => {
    const link = document.createElement('a');
    const title = table.title && table.title !== '' ? table.title : 'Example Response';
    link.textContent = `${title}`;
    const uniqueKey = `table-${index}-${questionId ?? crypto.randomUUID()}`;
    try {
      localStorage.setItem(`${uniqueKey}`, `${encodeURIComponent(table.outerHTML)}`);
      link.href = `guidance/table?content=${uniqueKey}`;

      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    } catch (error) {
      console.error(error);
      const items = { ...localStorage };
      for (const key in items) {
        if (key.includes('table-')) {
          try {
            localStorage.removeItem(key);
          } catch (error) {
            console.error(error);
          }
        }
      }

      link.href = '#';
    }

    if (table.parentNode) {
      table.parentNode.replaceChild(link, table);
    }
  });

  return htmlDoc.documentElement.outerHTML;
};

export function convertHtmlToText(html: string | undefined) {
  if (!html) {
    return '';
  }
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc?.body?.textContent;
}

export function findQuestionById(sections: Section[], questionId: NumberOrString) {
  for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
    const section = sections[sectionIndex];
    for (let questionIndex = 0; questionIndex < section.questions.length; questionIndex++) {
      const question = section.questions[questionIndex];
      if (question?.id === questionId) {
        return { sectionIndex, questionIndex };
      }
    }
  }
  return { sectionIndex: -1, questionIndex: -1 };
}
